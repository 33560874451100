import React from "react";
import { connect } from "react-redux";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getCauseBySlug } from "../../store/actions/causes_action";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { addDonation } from "../../store/actions/donations_action";
import DonationForm from "../../components/donation/DonationForm";
const DonateCalam = ({
  getCauseBySlug,
  addDonation,
  cause: { cause, loading },
  match,
}) => {
  useEffect(() => {
    if (match.params.slug) {
      getCauseBySlug(match.params.slug);
    }
  }, [match.params.slug]);

  console.log("Causes data:", cause)

  return (
    <div>
      <Header active="" />
      <section className="main-cause pb-50" style={{ paddingTop: "30px" }}>
        <div className="container">
          <div className="stic">
            <div className="row">
              <div className="col-md-7">
                <div className="donation-form">
                  <h3 className="details">Donation Details</h3>
                  <DonationForm />

                  <hr />
                  <div className="bank-details">
                    <h3>
                      Our Bank details to make donation from net-banking /
                      Internet Banking.
                    </h3>
                    <div className="main-details">
                      <h3>State Bank of India Details</h3>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Beneficiary Name</div>
                        <div className="value">
                          National Organisation for Social Empowerment
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Types Of Account</div>
                        <div className="value">Current</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Account Number</div>
                        <div className="value">32344893165</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch</div>
                        <div className="value">
                          Sagar Tower, District Centre, Janakpuri, New Delhi-
                          110058
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch Code</div>
                        <div className="value">001706</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">IFSC Code</div>
                        <div className="value">SBIN0001706</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">MICR Code</div>
                        <div className="value">110002054</div>
                      </div>
                    </div>
                    <hr />
                    <div className="main-details">
                      <h3>ICICI Bank Details</h3>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Beneficiary Name</div>
                        <div className="value">
                        National Organisation for Social Empowerment
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Types Of Account</div>
                        <div className="value">Current</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Account Number</div>
                        <div className="value">032205001782</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch</div>
                        <div className="value">
                        SAFDARJUNG ENCLAVE, NEW DELHI 110029
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch Code</div>
                        <div className="value">000322</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">IFSC Code</div>
                        <div className="value">ICIC0000322</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">MICR Code</div>
                        <div className="value">110229044</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="sidebar"
                  style={{ position: "sticky", top: "10px" }}
                >
                  <h3>You Are Donating Towards</h3>


                  {cause && (
                    < div className="banner-ei">
                      <div className="main-image">
                        <img
                          src={
                            cause.featured_image
                              ? cause.featured_image
                              : "/assets/images/22811264.jpg"
                          }
                          style={{
                            height: "400px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="row">
                      <div className="col-md-12">
                        <div>
                          {cause.document &&
                            cause.document.map((item) => {
                              return (
                                <div>
                                  <iframe
                                    src={item}
                                    style={{ width: "100%", height: "550px" }}
                                    frameborder="0"
                                  ></iframe>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div> 
                      <div id="home" className="main-content">
                        <div className="#home">
                          <h2>{cause.name}</h2>
                          <p className="disease">
                            <strong>
                              <i className="fa fa-ambulance"> </i>{" "}
                              {cause.disease}
                            </strong>
                          </p>
                        </div>
                      </div>
                      
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      <Footer />
    </div >
  );
};

const mapStateToProps = (state) => ({ cause: state.cause });

const mapDispatchToProps = { getCauseBySlug, addDonation };

export default connect(mapStateToProps, mapDispatchToProps)(DonateCalam);
